import React, { useContext, useEffect, useState } from "react";

// icons
import { CloseIcon } from "../../../../assets/icons/CloseIcon";

// helpers
import { generateSkinImage } from "../../../../helpers/generateSkinImage";

// context
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// types
import type { CartProduct } from "../../../../context/cart-provider/CartProvider.types";

// styles
import styles from "./CartProduct.module.scss";
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";

type CartProductProps = {
  product: CartProduct;
};

export function CartProduct({ product }: CartProductProps) {
  const { deleteSkinFromCart } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  return (
    <div className={styles.container}>
      <div className={styles.productWrapper}>
        <div className={styles.productInfo}>
          <div className={styles.imageWrapper}>
            <img src={generateSkinImage(product.class_id)} alt={product.name} />
          </div>
          <div className={styles.info}>
            <p className={styles.title}>{product.name}</p>
          </div>
        </div>
        <p className={styles.price}>
          {getCurrencySymbol()} {product.price}
        </p>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.totalPriceWrapper}>
        <p>
          {getCurrencySymbol()}{" "}
          {(Number(product.price) * product.quantity).toFixed(2)}
        </p>
        <div
          className={styles.removeWrapper}
          onClick={() => deleteSkinFromCart(product.id)}
        >
          <CloseIcon color="#a21916" width={13} height={13} /> Remove
        </div>
      </div>
    </div>
  );
}
