// components
import Button from "../../../../components/button/Button";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogRecomandationItem.module.scss";

type BlogRecomendationItemProps = {
  recomendation: BlogType;
};

export function BlogRecomendationItem({
  recomendation,
}: BlogRecomendationItemProps) {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{recomendation.title}</h1>
      <div className={styles.titleWrapper}>
        <div className={styles.descriptionWrapper}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: recomendation.content || "",
            }}
          />
          <div className={styles.buttonWrapper}>
            <Button variant="secondary" width="auto">
              Read More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
